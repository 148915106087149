import * as React from "react";
import { Link } from "gatsby";
import * as style from "../styles/404.module.css";

const NotFoundPage = () => {
    return (
        <main className={style.notFound}>
            <h1 >
                Cameo Studios
            </h1>
            <p>
                Sorry, we couldn’t find what you were looking for.
                <br />
                <br />
                <Link to="/">Go home?</Link>
            </p>
        </main>
    );
};

export default NotFoundPage;
